<template>
  <div>
    <v-row>
      <v-col cols="12" md="8">
        <div v-if="selectedTable">
          <amphitheater :selected="selectedTable" @change="value => selectedTable = value" :active="selectTables" width="90%" height="100%"></amphitheater>
        </div>
      </v-col>
      <v-col  cols="12" md="3">
        <v-card
            color="#EDF4F7"
            tile
            elevation="0"
            class="pa-3 ma-5">
            <h3>Datos de la mesa</h3>
          <div class="mt-5">
          <v-select :items="selectTables" v-model="selectedTable" outlined></v-select>
          <v-text-field  v-model="tableCapacity" outlined hint="0 es Deshabilitado"></v-text-field>
          </div>
          <v-btn color="orange" @click="updateTable" class="white--text" block :loading="sending">Guardar</v-btn>
        </v-card>
      </v-col>

    </v-row>

  </div>
</template>

<script>
import {AmphiEvents} from "@/services/api";
import AuthService from "@/services/auth.service";
import Amphitheater from "@/components/Amphitheater";

export default {
  name: "Tables",
  components: {Amphitheater},
  metaInfo() {
    return {
      title: "Anfiteatro | La Marina Anfiteatro",
      meta: [
        {name: 'description', content: 'Administrador de La Marina Resort Anfiteatro'},
      ]
    }
  },
  data(){
    return {
      selectedTable: null,
      selectTables: [],
      tableCapacity: null,
      tables: [],
      sending: false
    }
  },
  mounted() {
    this.getTables()
  },
  methods:{
    getTables(){

      this.selectedTable = null

      AmphiEvents(AuthService.getUserToken(), '').getAmphitheater()
          .then(response =>
          {
            let tables = response.data.data
            let arrTables = this.tables
            this.selectTables = tables.map(function(elem) {
              let tableObject = {
                capacity: elem.capacity,
                value: elem.name,
                id: elem.id
              }
              arrTables.push(tableObject)
              return elem.name;
            });
            this.selectedTable = this.selectTables[0]
            this.$store.commit('setLoading', false)
          })

    },
    getTable(){
      return this.tables.find( elem => elem.value === this.selectedTable)
    },
    updateTable(){
      this.sending =  true
      AmphiEvents().updateTable(this.getTable().id, this.tableCapacity).then(
          () => this.sending =  true
      );
    }
  },
  watch:{
    selectedTable(){
      this.tableCapacity = this.getTable().capacity
    }
  }
}
</script>

<style scoped>

</style>